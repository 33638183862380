import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconTagCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 60 60" {...props}>
			<path
				d="m11.87 19.11-.83 9.11c-.12 1.35-.18 2.03-.07 2.68a5 5 0 0 0 .6 1.64c.32.57.8 1.05 1.77 2.02l12.94 12.93c1.97 1.97 2.95 2.96 4.09 3.33a5 5 0 0 0 3.1 0c1.13-.37 2.13-1.36 4.1-3.34l9.9-9.9c1.99-1.98 2.97-2.97 3.34-4.12a5 5 0 0 0 0-3.09c-.37-1.14-1.36-2.13-3.34-4.1l-12.9-12.91a10.8 10.8 0 0 0-2.04-1.8 5 5 0 0 0-1.64-.59 10.8 10.8 0 0 0-2.7.08l-9.08.82c-2.36.21-3.54.32-4.47.84a5 5 0 0 0-1.94 1.94c-.5.92-.61 2.09-.83 4.43v.03Z"
				fill="#E6F2F3"
			/>
			<path d="M24.28 24.28a2.5 2.5 0 1 0-3.53-3.53 2.5 2.5 0 0 0 3.53 3.53Z" fill="#E6F2F3" />
			<path
				d="m11.87 19.11-.83 9.11c-.12 1.35-.18 2.03-.07 2.68a5 5 0 0 0 .6 1.64c.32.57.8 1.05 1.77 2.02l12.94 12.93c1.97 1.97 2.95 2.96 4.09 3.33a5 5 0 0 0 3.1 0c1.13-.37 2.13-1.36 4.1-3.34l9.9-9.9c1.99-1.98 2.97-2.97 3.34-4.12a5 5 0 0 0 0-3.09c-.37-1.14-1.36-2.13-3.34-4.1l-12.9-12.91a10.8 10.8 0 0 0-2.04-1.8 5 5 0 0 0-1.64-.59 10.8 10.8 0 0 0-2.7.08l-9.08.82c-2.36.21-3.54.32-4.47.84a5 5 0 0 0-1.94 1.94c-.5.92-.61 2.09-.83 4.43v.03Z"
				stroke="#027F88"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M24.28 24.28a2.5 2.5 0 1 0-3.53-3.53 2.5 2.5 0 0 0 3.53 3.53Z"
				stroke="#027F88"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</Icon>
	);
}
