import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconUserCheckCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 60 60" {...props}>
			<path d="M12.5 20a10 10 0 1 0 20 0 10 10 0 0 0-20 0Z" fill="#E6F2F3" />
			<path
				d="M37.5 47.5c0-5.52-6.72-10-15-10-8.28 0-15 4.48-15 10m45-22.5-10 10-5-5m-15 0a10 10 0 1 1 0-20 10 10 0 0 1 0 20Z"
				stroke="#027F88"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</Icon>
	);
}
