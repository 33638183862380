import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconLaptopCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 61 60" {...props}>
			<path
				d="M51.58 50H9.08a3.75 3.75 0 1 1 0-7.5h1.25v-22c0-2.8 0-4.2.55-5.27a5 5 0 0 1 2.18-2.19c1.07-.54 2.47-.54 5.27-.54h24c2.8 0 4.2 0 5.27.54a5 5 0 0 1 2.19 2.19c.54 1.07.54 2.47.54 5.26V42.5h1.25a3.75 3.75 0 1 1 0 7.5Z"
				fill="#E6F2F3"
			/>
			<path
				d="M10.33 42.5H9.08a3.75 3.75 0 1 0 0 7.5h42.5a3.75 3.75 0 1 0 0-7.5h-1.25m-40 0h40m-40 0v-22c0-2.8 0-4.2.55-5.27a5 5 0 0 1 2.18-2.19c1.07-.54 2.47-.54 5.27-.54h24c2.8 0 4.2 0 5.27.54a5 5 0 0 1 2.19 2.19c.54 1.07.54 2.47.54 5.26V42.5"
				stroke="#027F88"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</Icon>
	);
}
