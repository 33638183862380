import { Meta, Title } from '@solidjs/meta';
import { BreadcrumbItem, Button, Container, Heading, HorizontalRule, Link, Page, Section, TextLink } from '@troon/ui';
import { For, Switch, Match } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { IconCircleCheckCustom } from '@troon/icons/circle-check-custom';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { IconCircleCheck } from '@troon/icons/circle-check';
import { Dynamic } from 'solid-js/web';
import { IconMapCustom } from '@troon/icons/map-custom';
import { IconUserCheckCustom } from '@troon/icons/user-check-custom';
import { IconArrowsReload01Custom } from '@troon/icons/arrows-reload-01-custom';
import { IconLaptopCustom } from '@troon/icons/laptop-custom';
import { IconTagCustom } from '@troon/icons/tag-custom';
import { IconUsersCustom } from '@troon/icons/users-custom';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { PictureSection } from '../../components/layouts/picture-section';
import type { JSX, ParentProps } from 'solid-js';
import type { IconComponent } from '@troon/icons';
import type { RouteDefinition } from '@solidjs/router';

export default function TroonCardComparison() {
	return (
		<>
			<Title>Troon Access - The New & Improved Troon Card | Troon Access | Troon</Title>
			<Meta
				name="description"
				content="Learn about the differences & similarities between the Troon Card and Troon Access"
			/>

			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/web/hero/access-20241218.jpeg`}
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/access">Troon Access</BreadcrumbItem>
						<BreadcrumbItem href="/access/troon-card-vs-troon-access">Troon Card vs. Troon Access</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1">Troon Access - The New & Improved Troon Card</Heading>
				<p>Troon Access has replaced the Troon Card moving forward with some key similarities & differences.</p>
			</Hero>

			<Container>
				<Page>
					<PictureSection src={`${getConfigValue('IMAGE_HOST')}/web/hero/daily-fee-and-resort.jpg`}>
						<Heading as="h2">What is Troon Access?</Heading>
						<p>
							Troon Access is a modern evolution of the Troon Card experience. Designed with you in mind, Troon Access
							offers an enhanced digital platform, expanded benefits, and a seamless way to enjoy the game you love at
							over 150 premier courses around the world. With an expanded booking window and a reliable 15% savings,
							we’re ensuring our Troon Access members get even more out of their experience, while still preserving the
							deeper benefits within 78 hours. But we’re just getting started. Troon Access is set to become much more
							than a discount program, evolving into a community with exclusive, club-like benefits.
						</p>
					</PictureSection>

					<HorizontalRule />

					<Section>
						<Heading as="h2" class="text-center">
							Key Differences
						</Heading>

						<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-8 xl:grid-cols-3">
							<For each={differences}>
								{(diff) => (
									<div class="flex flex-col items-center gap-2 rounded border border-neutral p-4 text-center md:p-8">
										<Dynamic component={diff.icon} class="size-16" />
										<Heading as="h3" size="h4">
											{diff.title}
										</Heading>
										<p class="text-neutral-800">{diff.content}</p>
									</div>
								)}
							</For>
						</div>
					</Section>

					<div class="overflow-x-auto rounded bg-brand-700 text-white">
						<table class="w-full">
							<thead>
								<tr>
									<th />
									<ColHead>Troon Card</ColHead>
									<ColHead access>Troon Access</ColHead>
									<ColHead access>Troon Access+</ColHead>
								</tr>
							</thead>
							<tbody>
								<For each={comparison}>
									{(comp) => (
										<tr>
											<th class="border border-white/20 px-6 py-4 text-start font-normal" scope="row">
												{comp.heading}
											</th>
											<CompCell value={comp.card} />
											<CompCell access value={comp.access} />
											<CompCell access value={comp.accessPlus} />
										</tr>
									)}
								</For>
							</tbody>
						</table>
					</div>

					<HorizontalRule />

					<Container size="small">
						<Section>
							<Heading as="h2" class="text-center">
								Similarities
							</Heading>
							<For each={similarities}>
								{(item) => (
									<div class="flex flex-row gap-4 rounded border border-neutral p-4 md:gap-8 md:p-8">
										<IconCircleCheckCustom class="size-16 shrink-0" />
										<div class="flex flex-col gap-2">
											<Heading as="h2" size="h5">
												{item.title}
											</Heading>
											<p>{item.content()}</p>
										</div>
									</div>
								)}
							</For>
						</Section>
					</Container>

					<HorizontalRule />

					<div class="flex justify-center">
						<Button as={Link} href="/access" class="size-fit grow-0">
							Troon Access <IconArrowRightMd />
						</Button>
					</div>
				</Page>
			</Container>
		</>
	);
}

function ColHead(props: ParentProps & { access?: boolean }) {
	return (
		<th
			class={twJoin('border border-white/20 px-6 py-4 text-center font-semibold', props.access && 'bg-brand-600')}
			scope="col"
		>
			{props.children}
		</th>
	);
}

function CompCell(props: { access?: boolean; value: string | boolean }) {
	return (
		<td class={twJoin('border border-white/20 px-6 py-4 text-center', props.access && 'bg-brand-600')}>
			<Switch fallback="---">
				<Match when={typeof props.value === 'string'}>{props.value}</Match>
				<Match when={props.value}>
					<IconCircleCheck class="size-6" />
				</Match>
			</Switch>
		</td>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const differences: Array<{ icon: IconComponent; title: string; content: string }> = [
	{
		icon: IconMapCustom,
		title: 'National Access',
		content:
			'Troon Access offers nationwide benefits at all participating courses—there are no more regional or state cards.',
	},
	{
		icon: IconUserCheckCustom,
		title: 'Individual Membership',
		content: 'While there are no twosome or foursome equivalents, we do offer bulk discounts (information below).',
	},
	{
		icon: IconArrowsReload01Custom,
		title: 'Renewal Dates',
		content: 'Membership is valid for one year from your purchase date (unlike the calendar-year Troon Card).',
	},
	{
		icon: IconLaptopCustom,
		title: 'Easier Booking',
		content:
			'Book directly on Troon.com—no need for a separate EZLinks login. Non-EZLinks courses will still need to be booked by phone.',
	},
	{
		icon: IconTagCustom,
		title: 'More Exclusive Benefits',
		content: 'Invitations to member-only events & tournaments. Exclusive perks & offers from partners.',
	},
	{
		icon: IconUsersCustom,
		title: 'Sunsetting 2-for-1s',
		content:
			'We’ve removed 2-for-1 offers, which were often a source of frustration for our cardholders and our courses.',
	},
];

const comparison: Array<{
	heading: string;
	card: string | boolean;
	access: string | boolean;
	accessPlus: string | boolean;
}> = [
	{ heading: 'Cost', card: 'Up to $999/calendar year', access: '$249/yr', accessPlus: '$399/yr' },
	{ heading: 'Access more than 150 courses', card: 'National Card Only', access: true, accessPlus: true },
	{ heading: '15% off all tee times', card: 'At check-in', access: 'When booking', accessPlus: 'When booking' },
	{ heading: 'Up to 50% off tee times within 78 hour booking window', card: true, access: false, accessPlus: true },
	{ heading: 'Access to member-only events', card: false, access: true, accessPlus: true },
	{ heading: 'Exclusive partner discounts', card: false, access: true, accessPlus: true },
	{ heading: '2 for 1s', card: true, access: false, accessPlus: false },
	{ heading: '10% off course merchandise', card: true, access: true, accessPlus: true },
];

const similarities: Array<{ title: string; content: () => JSX.Element }> = [
	{
		title: 'Unparalleled Savings',
		content: () => (
			<>
				For 27 years, the Troon Card has been the best way to save at Troon courses. Now, with Troon Access and Troon
				Access+, the savings are even better! For example, with a National Twosome card ($699), the cardholder would
				break even in 18 rounds (14 rounds with the Arizona Twosome card).{' '}
				<b>
					With Troon Access and Troon Access+, the member will reach that in just 9 rounds—less than one round per
					month!
				</b>
			</>
		),
	},
	{
		title: 'Troon Rewards',
		content: () =>
			'The Troon Rewards program remains unchanged. You will still generate points that can be redeemed for free rounds and status tiers will remain the same. Your status-level discounts will stack on your Access+ rates within 78 hours, but will not stack on the 15% discount outside of the 78 hour window. Status discounts will be applied on top of your Access rate at the course.',
	},
	{
		title: 'Patriot Card',
		content: () => (
			<>
				We do not have a separate product for these two, but we still offer military and first responder discounts via a
				promo code. To request a promo code and verify your status, please{' '}
				<TextLink href="/access/military-and-first-responders">contact us</TextLink>.
			</>
		),
	},
	{
		title: 'Discounted Rates',
		content: () =>
			'The way Troon Access+ rates are configured are exactly the same as the Troon Card. Nothing is changing in the way your 78 hour rates are determined. Unlike the Troon Card, though, you will get an automatic 15% off savings on tee times booked outside of that 78 hour window (up to 60 days in advance). The 15% off rate will be visible at the time of booking. ',
	},
];
